function getDomain() {
  if (window.location.host.indexOf("localhost") > -1) {
    return "";
  }

  if (window.location.host.indexOf("10.192.74.52") > -1) {
    return "10.192.74.52";
  }

  if (window.location.host.indexOf("bpa.iptelecom.pt") > -1) {
    return "bpa.iptelecom.pt";
  }

  return ".kstk.pt";
}

function setCookie(name, value, days) {
  var domain = getDomain();

  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/; Domain=" + domain;
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  var domain = getDomain();
  document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" + domain;
}

// KSTK Auth related methods
function setAuthentication(auth) {
  setCookie("KSTKAuth", JSON.stringify(auth), 0.333);
}

function getAuthentication() {
  var cookie = getCookie("KSTKAuth");
  if (cookie) {
    return JSON.parse(cookie);
  } else {
    return null;
  }
}

function deleteAuthentication() {
  eraseCookie("KSTKAuth");
}
